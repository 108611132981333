/* eslint-disable @typescript-eslint/camelcase */
export default {
    lang: 'Nederlands',
    auto: 'Automatisch',
  
    close: 'Afsluiten',
    no: 'Nee',
    yes: 'Ja',
    cancel: 'Annuleren',
    ok: 'OK',
  
    start: 'Start',
    stop: 'Stop',
    submit: 'Verzend',
    edit: 'Aanpassen',
    delete: 'Verwijderen',
    todo: 'Takenlijst',
    resume: 'Hervat',
    pause: 'Pauzeren',
    force_start: 'Forceer Starten',
    toggle_sequential: 'Sequentiële Downloads Inschakelen',
    info: 'Informatie',
    reset: 'Reset',
    login: 'Inloggen',
    search: 'Zoeken',
    refresh: 'Vernieuwen',
    location: 'Locatie',
    rename: 'Hernoemen',
    trigger_application_shutdown: 'qBittorrent afsluiten',
    reannounce: 'Opnieuw Aankondigen',
    recheck: 'Opnieuw Controleren',
  
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
  
    name: 'Naam',
    size: 'Grootte',
    progress: 'Vooruitgang',
    status: 'Status',
    seeds: 'Seeds',
    peers: 'Peers',
    dl_speed: 'DL Snelheid',
    up_speed: 'UP Snelheid',
    eta: 'Resterende Tijd',
    ratio: 'Deelverhouding',
    added_on: 'Toegevoegd Op',
  
    settings: 'Instellingen',
    logs: 'Logs',
    light: 'Licht',
    dark: 'Donker',
  
    all: 'Alles',
    category: 'Categorie |||| Categoriën',
    uncategorized: 'Zonder Categorie',
    tag: 'Label |||| Labels',
    untagged: 'Zonder Label',
    others: 'Overige',
    sites: 'Sites',
    files: 'Bestanden',
    less: 'Minder',
    more: 'Meer',
    feed: 'Feed',
    date: 'Datum',
    query: 'Aanvraag',
    plugin: 'Plugin |||| Plugins',
    action: 'Actie |||| Acties',
    search_engine: 'Zoekmachine',
    usage: 'Gebruik',
    plugin_manager: 'Plugins Beheren',
    update_plugins: 'Plugins Updaten',
  
    preferences: {
      change_applied: 'Nieuwe voorkeuren opgeslagen',
      downloads: 'Downloads',
      adding_torrent: 'Bij het toevoegen van een torrent',
      create_subfolder_enabled: 'Maak een subfolder voor torrents met meerdere bestanden',
      start_paused_enabled: 'Downloads niet automatisch starten',
      auto_delete_mode: '.torrent bestanden automatisch verwijderen',
      preallocate_all: 'Schijfruime toewijzen voor alle bestanden',
      incomplete_files_ext: 'Voeg .!qB-extensie toe aan onvolledige bestanden',
      saving_management: 'Opslag Beheer',
      auto_tmm_enabled: 'Standaard Torrent Beheermodus',
      torrent_changed_tmm_enabled: 'Bij wijziging van Torrent Categorie',
      save_path_changed_tmm_enabled: 'Bij wijziging van standaard opslagpad',
      category_changed_tmm_enabled: 'Bij wijziging van Categorie opslagpad',
      auto_mode: 'Automatisch',
      manual_mode: 'Handmatig',
      switch_torrent_mode_to_manual: 'Schakel deze torrent over naar handmatige modus',
      move_affected_torrent: 'Verplaats de betreffende torrents',
      save_path: 'Standaard opslagpad',
      temp_path: 'Opslagpad onvolledige torrents',
      export_dir: 'Kopieer .torrent bestanden naar',
      export_dir_fin: 'Kopieer .torrent bestanden voor voltooide downloads naar',
  
      speed: 'Snelheid',
      global_rate_limits: 'Globale snelheidslimieten',
      alternate_rate_limits: 'Alternatieve snelheidslimieten',
      alternate_schedule_enable_time: 'Alternatieve snelheidlimieten inplannen',
      apply_speed_limit: 'Instellingen snelheidslimieten',
      dl_limit: 'Downloaden (KiB/s)',
      up_limit: 'Uploaden (KiB/s)',
      zero_for_unlimited: '0 betekent onbeperkt',
      schedule_from: 'Van',
      schedule_to: 'Tot',
      scheduler_days: 'Dagen',
      limit_utp_rate: 'Pas snelheidslimiet toe op het µTP-protocol',
      limit_tcp_overhead: 'Pas snelheidslimiet toe op transport overhead',
      limit_lan_peers: 'Pas snelheidslimiet toe op peers op LAN',
  
      connection: 'Verbindingen',
      bittorrent: 'BitTorrent',
  
      rss: 'RSS',
      rss_processing_enabled: 'Ophalen van RSS-feeds inschakelen',
      rss_auto_downloading_enabled: 'Automatisch downloaden van RSS-torrents inschakelen',
      rss_refresh_interval: 'RSS-feed verversingsinterval',
  
      webui: 'Web Gebruikersinterface',
      data_update_interval: 'Gegevens Update Interval (ms)',
      webui_remote_control: 'Web Gebruikersinterface (Bediening op afstand)',
      ip_address: 'IP-adres',
      ip_port: 'Poort',
      enable_upnp: 'Gebruik UPnP / NAT-PMP om de poort van mijn router door te sturen',
      authentication: 'Authenticatie',
      web_ui_username: 'Gebruikersnaam',
      web_ui_password: 'Wachtwoord',
      bypass_local_auth: 'Authenticatie omzeilen voor clients op localhost',
      bypass_auth_subnet_whitelist: 'Authenticatie omzeilen voor clients in gewhiteliste IP-subnetten',
      web_ui_session_timeout: 'Sessie time-out',
      web_ui_max_auth_fail_count: 'Verban client na opeenvolgende mislukte pogingen',
      web_ui_ban_duration: 'verban voor',
      web_ui_seconds: 'seconden',
      new_password: 'Wijzig huidig wachtwoord...',
  
      display_speed_in_title: 'Toon downloadsnelheid in paginatitel',
    },
  
    title: {
      _: 'Titel',
      add_torrents: 'Torrents Toevoegen',
      delete_torrents: 'Torrents Verwijderen',
      set_category: 'Categorie Instellen',
      edit_tracker: 'Tracker Bewerken',
      set_location: 'Locatie Instellen',
      recheck_torrents: 'Torrents Opnieuw Controleren',
    },
  
    label: {
      switch_to_old_ui: 'Schakel naar oude gebruikersinterface',
      create_subfolder: 'Maak submap aan',
      start_torrent: 'Start torrent',
      skip_hash_check: 'Sla hashcontroler over',
      in_sequential_order: 'Op volgorde',
      first_and_last_pieces_first: 'Eerste en laatste delen eerst',
  
      also_delete_files: 'Verwijder ook bestanden',
  
      auto_tmm: 'Automatische TMM',
  
      adding: 'Toevoegen…',
      reloading: 'Herladen…',
      deleting: 'Verwijderen…',
      moving: 'Verplaatsen…',
      moved: 'Verplaatst',
      next: 'Volgende',
      back: 'Vorige',
      confirm: 'Bevestigen',
      reannounced: 'Heraangekondigd',
      rechecking: 'Hercontroleren…',
      dht_nodes: '%{smart_count} node |||| %{smart_count} nodes',
      base_url: 'Base URL',
    },
  
    msg: {
      item_is_required: '%{item} is vereist',
    },
  
    dialog: {
      trigger_exit_qb: {
        title: 'qBittorrent afsluiten',
        text: 'Weet u zeker dat u qBittorrent wilt afsluiten?',
      },
      add_torrents: {
        placeholder: 'Upload torrents door ze naar hier te slepen,\nof click rechts op de bijlageknop om ze te selecteren.',
        hint: 'Één link per regel',
      },
      delete_torrents: {
        msg: 'Weet u zeker dat u de geselecteerde torrents uit de tranferlijst wilt verwijderen?',
        also_delete_same_name_torrents: 'Verwijder ook een torrent met dezelfde naam |||| Verwijder ook %{smart_count} torrents met dezelfde naam',
      },
      set_category: {
        move: 'Weet u zeker dat u de geselecteerde torrents naar de categorie %{category} wilt verplaatsen?',
        reset: 'Weet u zeker dat u de categorie van geselecteerde torrents wilt resetten?',
        also_move_same_name_torrents: 'Verplaats ook een torrent met dezelfde naam |||| Verplaats ook %{smart_count} torrents met dezelfde naam',
      },
      switch_locale: {
        msg: 'Weet u zeker dat u de taal wilt veranderen naar %{lang}?\nDeze actie zal de pagina herladen.',
      },
      recheck_torrents: {
        msg: 'Weet u zeker dat u de torrents opnieuw wilt controleren?',
      },
      rss: {
        add_feed: 'Feed toevoegen',
        feed_url: 'Feed URL',
        auto_refresh: 'Automatisch Vernieuwen',
        auto_download: 'Automatisch Downloaden',
        delete_feeds: 'Weet u zeker dat u de selecteerde feeds wilt verwijderen?',
        date_format: '%{date} (%{duration} geleden)',
      },
      rss_rule: {
        add_rule: 'Regel Toevoegen',
        new_rule_name: 'Naam van de nieuwe regel',
        delete_rule: 'Weet u zeker dat u de geselecteerde regel wilt verwijderen?',
        title: 'RSS Downloader',
        rule_settings: 'Regelinstellingen',
  
        use_regex: 'Gebruik Regex',
        must_contain: 'Moet Bevatten',
        must_not_contain: 'Mag Niet Bevatten',
        episode_filter: 'Filter Op Aflevering',
        smart_episode: 'Gebruik Slimme Aflevering Filter',
        assign_category: 'Assign Category',
  
        apply_to_feeds: 'Pas Regel Toe op Feeds',
      },
    },
  
    category_state: {
      _: 'Status',
  
      downloading: 'Downloaden',
      seeding: 'Seeding',
      completed: 'Voltooid',
      resumed: 'Hervat',
      paused: 'Gepauzeerd',
      active: 'Actief',
      inactive: 'Niet Actief',
      errored: 'Fout',
    },
  
    torrent_state: {
      error: 'fout',
      missingFiles: 'ontbrekendeBestanden',
      uploading: 'uploaden',
      pausedUP: 'gepauzeerdUP',
      queuedUP: 'wachtrijdUP',
      stalledUP: 'vastgelopenUP',
      checkingUP: 'controleUP',
      forcedUP: 'geforceerdUP',
      allocating: 'toewijzen',
      downloading: 'downloaden',
      metaDL: 'metaDL',
      pausedDL: 'gepauzeerdDL',
      queuedDL: 'wachtrijdDL',
      stalledDL: 'vastgelopenDL',
      checkingDL: 'controleDL',
      forceDL: 'geforceerdDL',
      checkingResumeData: 'controleHervattingsData',
      moving: 'verplaatsen',
      unknown: 'onbekend',
    },
  }
