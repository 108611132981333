





















import {Component, Prop} from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class PreferenceRow extends Vue {
  @Prop(String)
  readonly i18nKey?: string
}
