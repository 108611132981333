








































import { throttle } from 'lodash';
import Vue from 'vue';
import { mapMutations } from 'vuex';

import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

@Component({
  methods: {
    ...mapMutations([
      'setQuery',
    ]),
  },
})
export default class MainToolbar extends Vue {
  @Prop(Boolean)
  readonly value!: boolean

  setQuery!: (_: string | null) => void

  focusedSearch = false

  get searchQuery() {
    return this.$store.state.query;
  }

  get phoneLayout() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get searchBarExpanded() {
    return this.phoneLayout && (this.focusedSearch || !!this.searchQuery);
  }

  @Emit('input')
  toggle() {
    return !this.value;
  }

  onSearch = throttle(async (v: string) => {
    // avoid input lag
    await this.$nextTick();
    this.setQuery(v || null);
  }, 400)
}
